.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

@font-face {
    font-family: 'ambleregular';
    src: url('../fonts/amble/amble_regular/Amble-Regular-webfont.eot');
    src: url('../fonts/amble/amble_regular/Amble-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/amble/amble_regular/Amble-Regular-webfont.woff') format('woff'), url('../fonts/amble/amble_regular/Amble-Regular-webfont.ttf') format('truetype'), url('../fonts/amble/amble_regular/Amble-Regular-webfont.svg#ambleregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ambleitalic';
    src: url('../fonts/amble/amble_italic/Amble-Italic-webfont.eot');
    src: url('../fonts/amble/amble_italic/Amble-Italic-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/amble/amble_italic/Amble-Italic-webfont.woff') format('woff'), url('../fonts/amble/amble_italic/Amble-Italic-webfont.ttf') format('truetype'), url('../fonts/amble/amble_italic/Amble-Italic-webfont.svg#ambleregular') format('svg');
    font-weight: normal;
    font-style: normal;
}



:root {
    --bg: #2f2f2f;
    --text: #f2f2f2;
    --primary: #ccf5c4;
    --bg-highlight: #5F4239;
    --text-highlight: #EEE9D6;
    --font-regular: 'ambleregular', "Helvetica Neue", Helvetica, Arial, sans-serif;
}

body {
    width: 100%;
    height: 100%;
    font-family: var(--font-regular);
    color: var(--text);
    background-color: var(--bg);
    font-size: 100%;
}

html {
    width: 100%;
    height: 100%;
    font-size: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 20px;
    font-family: var(--font-regular);
    font-weight: 300;
    letter-spacing: 1px;
}

h2 {
    font-size: 2.1em;
}

h3 {
    font-size: 1.3em;
    font-weight: 400;
}

p {
    margin: 0 0 20px;
    font-size: 1em;
    line-height: 1.5;
}

@media (min-width: 768px)  {
    p {
        margin: 0 0 25px;
    }
}

.list--services {
    margin: 0 0 25px;
}

blockquote {
    padding: 0 0 0 50px;
    border: 0;
}

blockquote p {
    font-family: 'ambleitalic';
    font-size: 18px;
    line-height: 1.5;
    margin: 0;
    padding: 0;
}

blockquote p + p {
    margin-top: 50px;
}

blockquote .icon {
    display: inline-flex;
    vertical-align: text-top;
    padding: 0;
}
blockquote .icon--rotate-left {
    padding-right: 3px;
}
blockquote .icon--rotate-right {
    padding-left: 3px;
}


blockquote footer {
    padding: 25px 0 0 0;
    color: var(--bg);
}

em {
    font-style: italic;
    font-family: 'ambleitalic';
}

em:before {
    font-family: "FontAwesome";
    display: inline;
    color: var(--primary);
    content: "\f101 ";
    padding: 0 3px;
}

em:after {
    font-family: "FontAwesome";
    display: inline;
    color: var(--primary);
    content: "\f100 ";
    padding: 0 3px;
}

a {
    color: var(--primary);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

a:hover,
a:focus {
    text-decoration: none;
    color: #5b6659;
}

.light {
    font-weight: 400;
}

.navbar-custom {
    margin-bottom: 0;
    border-bottom: 1px solid rgba(242, 242, 242, 0.3);
    font-family: var(--font-regular);
    background-color: var(--bg);
}

.navbar-custom .navbar-brand {
    font-weight: 700;
    padding: 0;
    margin-bottom: 0;
    height: 80px;
}

.navbar-custom .navbar-brand a {
    display: block;
    height: 80px;
}

.navbar-custom .navbar-brand img {
    max-height: 100%;
}

.navbar-custom .navbar-brand:focus {
    outline: none;
}

.navbar-custom .navbar-brand .navbar-toggle {
    padding: 4px 6px;
    font-size: 16px;
    color: var(--text);
}

.navbar-custom .navbar-brand .navbar-toggle:focus,
.navbar-custom .navbar-brand .navbar-toggle:active {
    outline: none;
}

.navbar-custom a {
    color: var(--text);
}

.navbar-custom .navbar-nav > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
}

.navbar-custom .nav li.active {
    outline: none;
    background-color: rgba(242, 242, 242, 0.3);
}

.navbar-custom .nav li a {
    transition: background 0.3s ease-in-out;
}

.navbar-custom .nav li a:hover,
.navbar-custom .nav li a:focus,
.navbar-custom .nav li a.active {
    outline: none;
    background-color: rgba(242, 242, 242, 0.3);
}

@media (min-width: 768px)  {
    .navbar {
        border-bottom: none;
        letter-spacing: 1px;
        background: transparent;
        transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
    }

    .navbar-custom .navbar-brand {
        font-weight: 700;
        padding: 0;
        margin-bottom: 0;
        height: 80px;
    }

    .navbar-custom .navbar-brand a {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .top-nav-collapse {
        padding: 0;
        background-color: var(--bg);
    }

    .navbar-custom.top-nav-collapse {
        border-bottom: 1px solid rgba(242, 242, 242, 0.3);
    }
    .navbar-custom .navbar-nav > li > a {
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .navbar-custom .navbar-nav > li > a {
        font-size: 14px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.intro {
    display: table;
    width: 100%;
    height: auto;
    padding: 0;
    text-align: center;
    color: var(--bg);
    background: var(--bg) url(../img/Schneidekunst_Panorama.jpg) no-repeat bottom center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}

.intro .intro-body {
    display: table-cell;
    vertical-align: bottom;
}

.intro .intro-body .brand-heading {
    font-size: 40px;
}

.intro .intro-body .brand-heading img {
    display: block;
    max-width: 100%;
}

.intro .intro-body .intro-text {
    font-size: 18px;
}

.navbar-nav {
    margin-top: 0;
    margin-bottom: 0;
}

.navbar-custom .navbar-brand {
    font-weight: 700;
    padding: 0;
    margin-bottom: 0;
    height: 80px;
}

.navbar-custom .navbar-brand a {
    padding-top: 10px;
    padding-bottom: 10px;
}

.navbar-toggle {
    padding-top: 15px;
}

@media (min-width: 768px)  {
    .intro {
        height: 100%;
        padding: 0;
    }

    .intro .intro-body .brand-heading {
        font-size: 100px;
    }

    .intro .intro-body .intro-text {
        font-size: 25px;
    }
}

.btn-circle {
    width: 40px;
    height: 40px;
    margin-bottom: 60px;
    padding: 5px 8px;
    border: 2px solid #d2deb7;
    border-radius: 20px;
    font-size: 20px;
    color: #2d4d41;
    background: #d2deb7;
    transition: background 0.3s ease-in-out;
}

.btn-circle:hover,
.btn-circle:focus {
    outline: none;
    border: 2px solid #2d4d41;
    color: #2d4d41;
    background: rgba(45, 77, 65, 0.1);
}

.btn-circle .animated {
    transition-property: transform;
    transition-duration: 1s;
}
.btn-circle .icon {
    display: flex;
    height: 100%;

}

.btn-circle:hover .animated {
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.content-section {
    padding-top: 100px;
}

.about-section {
    border-top: 4px solid var(--bg);
}

.skills-section {
    width: 100%;
    padding: 50px 0;
    font-size: 14px;
    border-top: 2px solid var(--text);
}

.skills-section p + h3,
.skills-section ol + h3,
.skills-section ul + h3,
.skills-section li + h3 {
    margin-top: 20px;
}

.list-dotted, .skills-section ul {
    margin-left: 0;
    padding: 0 0 0 1.5em;
}

.list-dotted li, .skills-section ul li {
    margin: 3px 0;
    padding: 0 0 0 3px;
}


.skills-section .publishedDate {
    display: block;
    font-size: 12px;
    font-family: 'ambleitalic';
}

.skills-section .contentSnippet {
    display: block;
    font-size: 14px;
}

.skills-section .fb-page {
    margin-bottom: 20px;
}

.about-section,
.contact-section {
    background-color: var(--primary);
    color: var(--bg);
    width: 100%;
    padding: 50px 0;
}

.contact-section {
    border-top: 2px solid var(--bg);
}

.about-section a,
.contact-section a {
    color: var(--bg);
    border-bottom: 1px dotted var(--bg);
}

.about-section a:hover,
.contact-section a:hover {
    color: #2d4d41;
    border-bottom: 0;
}

.highlight-section {
    background-color: var(--bg-highlight);
    color: var(--text-highlight);
    width: 100%;
    padding: 50px 0;
    border-top: 2px solid var(--text-highlight);
}

.highlight-section a {
    color: var(--text-highlight);
}
.highlight-section a:not(.link--icon) {
    border-bottom: 1px dotted var(--text-highlight);
}
.highlight-section a:hover {
    border-bottom: 0;
}
.section-logo + p {
    padding-top: 20px;
}

.link--icon {
    border: 0;
    outline: none;
}
.link--icon:hover {
    opacity: 0.7;
}

.imprint {
    font-size: 13px;
}

.imprint h5,
.imprint p,
.imprint ol,
.imprint ul {
    margin-bottom: 1.5em;
}

.js .imprint {
    max-height: 0;
    overflow: hidden;
}

.js .imprint.open {
    max-height: 10000px;
    transition: max-height 0.8s;
}

#map {
    width: 100%;
    height: 200px;
}

@media (min-width: 768px)  {
    .content-section {
        padding-top: 50px;
    }

    #map {
        height: 400px;
    }
}

.btn {
    text-transform: uppercase;
    font-family: var(--font-regular);
    font-weight: 400;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.btn-default,
.contact-section .btn-default {
    border: 1px solid var(--bg);
    color: var(--bg);
    background-color: transparent;
}

.btn-default:hover,
.contact-section .btn-default:hover,
.btn-default:focus,
.contact-section .btn-default:focus {
    border: 1px solid #2d4d41;
    outline: none;
    color: #2d4d41;
    background-color: transparent;
}

ul.banner-social-buttons {
    margin: 0;

}

@media (max-width: 991px) {
    .btn-circle {
        margin-bottom: 30px;
    }
    div[class*="col-"] + div[class*="col-"] {
        margin-top: 30px;
    }
    .banner-social-buttons {
        text-align: left;
    }
}

@media (max-width: 767px) {
    .intro {
        height: 380px;
    }

    .intro .btn-circle {
        margin-bottom: 20px;
    }

    .about-section .img-responsive {
        max-width: 80%;
        margin: 0 auto;
    }

    .navbar-custom .navbar-brand {
        font-weight: 700;
        padding: 0;
        margin-bottom: 0;
        height: 80px;
        margin-left: 15px;
    }

    .navbar-custom .navbar-brand a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .navbar-custom .navbar-brand {
        font-weight: 700;
        padding: 0;
        margin-bottom: 0;
        height: 60px;
    }

    .navbar-custom .navbar-brand a {
        padding-top: 10px;
        padding-bottom: 10px;
        height: 60px;
    }

    .intro {
        height: 380px;
    }

    .intro .btn-circle {
        margin-bottom: 15px;
    }
}

footer {
    border-top: 2px solid var(--text);
    padding: 50px 0;
}
blockquote footer {
    border-top: 0;
}

footer p {
    margin: 0;
}
.days {
    display: inline-flex;
    width: 80px;
}

::selection {
    text-shadow: none;
    background: #fcfcfc;
    background: rgba(242, 242, 242, 0.2);
}

img::selection {
    background: transparent;
}

body {
    webkit-tap-highlight-color: rgba(242, 242, 242, 0.2);
}

/* Icons */
.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    pointer-events: none;
}
[data-icon-size="2x"] {
    width: 2em;
    height: 2em;
}
[data-icon-size="24"] {
    width: 24px;
    height: 24px;
}
[data-icon-size="12"] {
    width: 12px;
    height: 12px;
}
.icon--primary {
    fill: var(--primary);
    color: var(--primary);
}
.icon--text {
    fill: var(--text);
    color: var(--text);
}
.icon--highlight {
    fill: var(--text-highlight);
    color: var(--text-highlight);
}
.icon--top {
    vertical-align: top;
}
.icon--rotate-right {
    transform: rotate(90deg);
}

.icon--rotate-down {
    transform: rotate(180deg);
}

.icon--rotate-left {
    transform: rotate(-90deg);
}